<template>
  <div class="details">
    <div class="info-block">
      <van-row type="flex" align="center" justify="space-between">
        <van-col>
          <span class="info-block-title">故障详情</span>
        </van-col>
      </van-row>
      <van-field v-model="name" label="设备名称" placeholder="请输入用户名" />
      <van-field v-model="pos" label="设备位置" placeholder="请输入用户名" />
      <van-field v-model="time" label="维护时间" placeholder="请输入用户名" />
      <van-field
        v-model="person"
        label="维护人"
        required
        placeholder="请输入用户名"
      />
      <van-field name="radio" label="维修状态" required>
        <template #input>
          <van-radio-group v-model="radio" direction="horizontal">
            <van-radio name="1" :checked-color="defineStyle.themeColor"
              >已处理</van-radio
            >
            <van-radio name="2" :checked-color="defineStyle.themeColor"
              >未处理</van-radio
            >
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="message"
        rows="1"
        :autosize="{ minHeight: 88 }"
        label="故障原因"
        required
        type="textarea"
        placeholder="请输入留言"
      />
    </div>
    <div class="e-btn">
      <van-button type="primary" :color="defineStyle.themeColor" block
        >确认</van-button
      >
      <van-button
        @click="$router.go(-1)"
        type="primary"
        :color="defineStyle.color.gray"
        block
        >取消</van-button
      >
    </div>
  </div>
</template>
<script>
import { Field, RadioGroup, Radio, Button } from "vant";
export default {
  components: {
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Button.name]: Button,
  },
  data() {
    return {
      name: "",
      pos: "",
      time: "",
      person: "",
      radio: 1,
      message: "",
    };
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "../../../style/public.scss";
.details {
  width: 100%;
  .e-btn {
    margin: 0 16px;
    margin-top: 24px;
    > button:last-child {
      margin-top: 12px;
    }
  }
  .info-block {
    min-height: 44px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 4px;
    background: #fff;
    > div:first-child {
      padding: 0 16px;
      box-sizing: border-box;
    }
    &-title {
      position: relative;
      padding-left: 10px;
      line-height: 44px;
      font-size: 16px;
      font-weight: 400;
      color: $titleColor;
    }
    &-title:before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      height: 18px;
      width: 4px;
      background: $themeColor;
      border-radius: #{"4PX"};
    }
    .slot-icon {
      color: $themeColor;
      height: 52px;
      line-height: 52px;
    }
  }
  .info-block:before {
    content: "";
    position: absolute;
    box-sizing: border-box;
    pointer-events: none;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: #{"1PX"} solid #ebedf0;
    transform: scaleY(0.5);
  }
}
</style>